/* istanbul ignore file */

import dataTable from '../components/datatable';
import { actionsDataTableProps } from '../helpers/actionsDataTableProps';
import baseDataTable from './data_table_controller';
import jQuery from 'jquery';

/**
 * Extends normal data table and adds row selection capabilities
 */
export default class extends baseDataTable {
  static targets = [
    'context',
    'table',
    'searchInput',
    'span',
    'rangePicker',
    'exportUrl',
    'title',
    'primaryLinkUrl',
    'primaryLinkText',
    'secondaryLinkUrl',
    'secondaryLinkText',
    'exportSuccessful',
    'exportError',
    'exportErrorMessage',
    'exportEmailList',
    'exportInputEmail',
    'exportFormatInput',
    'closeModalButton',
    'selectStats',
    'actionsDiv',
    'transitionModal',
    'transitionActionsLabel',
    'transitionForm',
    'transitionFormNotes',
    'actionsContainer',
    'actionLoader',
    'successContent',
    'transitionSuccessful',
    'transactionError',
    'receivingBranchContainer',
    'receivingBranchInput',
    'transitionNotesError',
    'receivingBranchError',
    'closeTransitionModalButton',
  ];
  table = null;
  transitionUrl = null;
  transitionAction = null;
  transitionData = null;

  getTableContext(context) {
    return actionsDataTableProps[context];
  }

  postInitRangeFilters() {
    this.ajaxParams.created__lte = this.endDate.format('YYYY-MM-DD');
    this.ajaxParams.created__gte = this.startDate.format('YYYY-MM-DD');
  }

  filterByStatus(event) {
    let statusButton = event.target;
    let status = statusButton.dataset.status;
    let initialUrl = this.ajaxURL;
    let urlWithStatusParams = initialUrl;
    let activeButton = statusButton;

    if (status !== 'ALL') {
      urlWithStatusParams = initialUrl.includes('?')
        ? `${initialUrl}&status=${status}`
        : `${initialUrl}?status=${status}`;
    }

    this.setActiveButton(activeButton);
    this.redrawTableOptions = {
      tableTarget: this.tableTarget,
      ajaxURL: status == 'ALL' ? initialUrl : urlWithStatusParams,
      ajaxParams: this.ajaxParams,
      columns: this.columns,
      selectable: status == 'ALL' ? false : true,
    };

    if (status === 'ALL') {
      this.ajaxURL = urlWithStatusParams;
      this.filterStatus = status;
    }

    this.reDrawTable();
    this.postFilterByStatus(status);
  }

  /**
   * Draws the table using the specified parameters.
   */
  drawTable() {
    this.table = dataTable(
      this.tableTarget,
      this.ajaxURL,
      this.ajaxParams,
      this.columns,
      false,
    );
    this.addTableListeners();
    this.addModalListeners();
    this.addFormListeners();
  }

  reDrawTable() {
    this.hideCount();
    this.table = dataTable(
      this.redrawTableOptions.tableTarget,
      this.redrawTableOptions.ajaxURL,
      this.redrawTableOptions.ajaxParams,
      this.redrawTableOptions.columns,
      this.redrawTableOptions.selectable,
    );
    this.addTableListeners();
  }

  addTableListeners() {
    this.table.on('rowSelectionChanged', data => {
      this.displayCount(data);
      this.transitionData = data.map(item => item.id);
    });
  }

  displayCount(data) {
    if (data.length > 0) {
      this.selectStatsTarget.innerHTML = `${data.length} selected `;
      this.actionsDivTarget.removeAttribute('hidden');
    } else {
      this.selectStatsTarget.innerHTML = '';
      this.actionsDivTarget.setAttribute('hidden', 'hidden');
    }
  }

  hideCount() {
    this.actionsDivTarget.setAttribute('hidden', 'hidden');
  }

  addModalListeners() {
    this.transitionModalTarget.addEventListener('show.bs.modal', event => {
      // Button that triggered the modal
      let button = event.relatedTarget;

      // Extract info from data-bs-* attributes
      let action = button.getAttribute('data-transition');
      this.transitionNotesErrorTarget.innerHTML = '';
      this.receivingBranchErrorTarget.innerHTML = '';
      this.transitionFormNotesTarget.classList.remove('is-invalid');
      this.receivingBranchErrorTarget.classList.remove('is-invalid');
      this.transitionAction = action;
      if (action === 'in_progress') {
        this.populateBranches();
      }

      // Update the modal's content.
      this.transitionActionsLabelTarget.textContent = action.toUpperCase();
    });
  }

  postFilterByStatus(status) {
    const context = this.getTableContext(
      this.contextTarget.dataset.tableContext,
    );
    this.drawActionButtons(context.actionsMap, status.toLowerCase());
  }

  drawActionButtons(actionsMap, status) {
    let actionObj = actionsMap[status];
    let btnString = '';

    if (actionObj) {
      for (const [key, value] of Object.entries(actionObj)) {
        if (value !== null && value !== undefined && value !== '') {
          btnString += this.constructButton(value, key);
        }
      }
    } else {
      btnString = `<span class="text-success">Sorry, no actions available.</span>`;
    }
    this.actionsContainerTarget.innerHTML = btnString;
  }

  constructButton(api, ui) {
    return `
    <button data-transition="${api}" type="button" class="btn btn-sm btn-outline-success btn-outline-sucess mx-2" data-bs-toggle="modal" data-bs-target="#transitionActions">
         <i class="fa-solid fa-circle-check"></i> ${ui}
      </button>`;
  }

  addFormListeners() {
    this.transitionFormTarget.addEventListener('submit', event => {
      event.preventDefault();
      let notes = this.transitionFormNotesTarget.value;
      let url = this.prepareUrl(this.transitionAction);
      let receiving = this.transitionAction === 'complete';
      if (!this.validateForm()) {
        return;
      }
      this.actionLoaderTarget.removeAttribute('hidden');
      this.processTransition(url, notes, this.transitionData, receiving).then(
        response => {
          this.processResponse(response);
        },
      );
    });
  }

  validateReceivingBranchInput() {
    if (this.transitionAction === 'in_progress') {
      if (
        this.receivingBranchInputTarget.value === '' ||
        this.receivingBranchInputTarget.value === null ||
        this.receivingBranchInputTarget.value === 'Nothing Selected'
      ) {
        this.receivingBranchErrorTarget.innerHTML = `<span class="text-danger">Receiving branch is required</span>`;
        return false;
      } else {
        this.receivingBranchInputTarget.classList.remove('is-invalid');
        this.receivingBranchErrorTarget.innerHTML = '';
        return true;
      }
    }
    return true;
  }

  validateNotesInput() {
    if (
      this.transitionFormNotesTarget.value === '' &&
      (this.transitionAction === 'failed' ||
        this.transitionAction === 'rejected')
    ) {
      this.transitionFormNotesTarget.classList.add('is-invalid');
      this.transitionNotesErrorTarget.innerHTML = `<span class="text-danger">Notes are required</span>`;
      return false;
    } else {
      this.transitionFormNotesTarget.classList.remove('is-invalid');
      this.transitionNotesErrorTarget.innerHTML = '';
      return true;
    }
  }

  validateForm() {
    return this.validateReceivingBranchInput() && this.validateNotesInput();
  }

  prepareUrl(status) {
    const context = this.getTableContext(
      this.contextTarget.dataset.tableContext,
    );
    return `${context.transitionUrl}${status}/`;
  }

  async processTransition(url, notes, items, receiving) {
    const data = { notes: notes, items: items, receiving: receiving };
    if (this.transitionAction === 'in_progress') {
      data['branch_id'] = this.receivingBranchInputTarget.value;
    }
    try {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'X-CSRFToken': this.csrf_token.value,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        return false;
      }

      const response_data = await response.json();
      const response_status = await response.status;

      return { json: response_data, status: response_status };
    } catch (error) {
      console.error(error);
    }
  }

  processResponse(response) {
    if (response === false) {
      this.transitionErrorTarget.removeAttribute('hidden');
      this.postCleanup();
    } else {
      if (response.status == 200) {
        this.transitionSuccessfulTarget.removeAttribute('hidden');
        this.setSuccessMessage(response.json);
        this.postCleanup();
      } else {
        this.transitionErrorTarget.removeAttribute('hidden');
        this.postCleanup();
      }
    }
  }

  setSuccessMessage(response) {
    this.successContentTarget.innerHTML = `
    Operation successful! <strong>${response.valid_objects.count} items processed</strong>. <strong class="text-danger">${response.invalid_objects.count} items failed</strong> to process.`;
  }

  postCleanup() {
    this.actionLoaderTarget.setAttribute('hidden', true);
    jQuery(this.closeTransitionModalButtonTarget).trigger({ type: 'click' });
    this.reDrawTable();
    this.transitionFormNotesTarget.value = '';
    this.receivingBranchInputTarget.value = '';
    this.transitionData = null;
  }

  async fetchDispatchBranches() {
    try {
      const response = await fetch('/api/organisation-branches/');
      const data = await response.json();
      return data.results;
    } catch (error) {
      console.error(error);
    }
  }

  populateBranches() {
    this.receivingBranchContainerTarget.removeAttribute('hidden');
    let input = this.receivingBranchInputTarget;
    this.fetchDispatchBranches().then(function (branches) {
      branches.forEach(function (branch) {
        let option = document.createElement('option');
        option.value = branch['id'];
        option.text = branch['name'];
        input.appendChild(option);
      });
      jQuery(input).selectpicker('refresh');
    });
  }
}
